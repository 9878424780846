.shareButtons {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9e3d9730;
}
.shareButtons .container  {
    background-color: white;
    border: 1px solid var(--grey-10);
    border-radius: 5px;
    padding: 25px;
    z-index: 100;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 530px;
    position: relative;
    max-height: 90vh;
    overflow-y: scroll;
}
.shareButtons .container .close {
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 5px;
    cursor: pointer;
    transition: .2s;
}
.shareButtons .container .close.back {
    left: 2px;
    right: unset;
}
.shareButtons .container .close:hover {
    transform: scale(1.1);
}
.shareButtons .container  figure {
    height: 300px;
    display: flex;
    justify-content: center;
}
.shareButtons .container  figure img {
    height: 100%;
}

.shareButtons .container .buttons {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
}
.shareButtons .container .shareButton {
    width: 40px;
    height: 40px;
    transition: .2s;
    position: relative;
    cursor: pointer;
}
.shareButtons .container h3 {
    font-size: 17px;
    text-align: center;
}
.shareButtons .container p {
    text-align: center;
}
.shareButtons .container b {
    color: var(--pn);
}
.shareButtons .container .shareButton:hover {
    opacity: .8;
}

.shareButtons .container .buttons .icon {
    border: none;
    padding: 0px;
    font: inherit;
    cursor: pointer;
    background-color: var(--pn);
    color: white;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    padding: 5px;
}

.shareButtons .container .shareButton.facebook, .shareButtons .container .shareButton.clipboard {
    top: -75px;
} 
.shareButtons .container .shareButton.email, .shareButtons .container .shareButton.messenger {
    top: -50px;
} 
.shareButtons .container .shareButton.twitter, .shareButtons .container .shareButton.linkedin {
    top: -25px;
} 

.shareButtons .container .labels {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.shareButtons .container .label {
    background-color: var(--pn);
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}
.shareButtons .container .label .icon {
    max-width: 15px;
    max-height: 15px;
    cursor: pointer;
    transition: .2s;
}
.shareButtons .container .label .icon:hover {
    transform: scale(1.1);
}

.shareButtons .container .inputFieldWrapper, .shareButtons .container .form__field {
    max-width: unset;
}
.shareButtons .container .buttonWrapper {
    align-self: center;
}
.shareButtons .container .to_mails:hover + .infoText, .shareButtons .container .infoText:hover {
    opacity: 1;
    pointer-events: inherit;
}
.shareButtons .container .infoText {
    display: flex;
    font-size: 11px;
    text-align: left;
    border: 1px solid var(--pn);
    border-radius: 5px;
    padding: 5px 10px;
    width: max-content;
    opacity: 0;
    transition: .5s;
    position: absolute;
    top: 210px;
    background: white;
    pointer-events: none;
    z-index: 9;
}
.shareButtons .container .infoText:before {
    border-bottom: 5px solid var(--pn);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    height: 0;
    left: 10%;
    position: absolute;
    top: -5px;
    width: 0;
}
.shareButtons .container .message {
    background-color: var(--grey-5);
    padding: 5px;
    border-radius: 5px;
}