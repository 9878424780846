.shoppinglist {
    background-color: var(--grey);
    height: calc(100% - 40px);
    padding-top: 40px;
    min-height: calc(100vh - 50px);
    display: flex;
}
.shoppinglist .sidebar {
    width: 25%;
    height: 100%;
    min-height: calc(100vh - 90px);
    border-right: 1px solid var(--grey-20);
}
.shoppinglist .sidebar .sidebarItem {
    padding: 15px 5px;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 49px;
    cursor: pointer;
}
.shoppinglist .sidebar .sidebarItem:hover {
    background-color: var(--grey-5);
}
.shoppinglist  .sidebar .sidebarItem.active {
    background-color: #b77ab3;
}
.shoppinglist  .sidebar .sidebarItem.active  .inputFieldWrapper {
    margin-right: auto;
    height: 35px;
    max-height: 35px;
    margin-bottom: 0;
}
.shoppinglist .sidebarItem .icon {
    display: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: 0 5px;
}

.shoppinglist  .sidebar .sidebarItem.active .icon { 
    display: flex;
}

.shoppinglist .shoppinglist_content {
    width: 75%;
    height: 100%;
    min-height: calc(100vh - 90px);
    padding: 20px;
}
.shoppinglist .shoppinglist_content .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.shoppinglist .shoppinglist_content h2 {
    padding: 15px;
    color: var(--pn);
}

.shoppinglist .shoppinglist_content .list_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-bottom: 1px solid var(--grey-10);
}
.shoppinglist .shoppinglist_content .list_item.header {
    border-bottom: none;
}
.shoppinglist .shoppinglist_content .list_item .inputFieldWrapper, .shoppinglist .shoppinglist_content .list_item .inputField, .shoppinglist .shoppinglist_content .list_item .checkBox {
    flex: 1;
    max-width: unset;
}
.shoppinglist .shoppinglist_content .list_item .inputFieldWrapper, .shoppinglist .shoppinglist_content .list_item .checkBox {
    margin: 5px 0;
}
.shoppinglist .shoppinglist_content .list_item .icon {
    width: 15px;
    height: 15px;
    color: var(--pn);
    cursor: pointer;
    margin: 10px;
    transition: .2s;
}
.shoppinglist .shoppinglist_content .list_item .icon:hover {
    transform: scale(1.3);
}

#shoppinglistpage .alertBoxWrapper .alertBox {
    width: unset !important;
    max-width: 750px;
}